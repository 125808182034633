@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/Nunito-Light.2fd6ab29.woff2) format('woff2'), url(/static/media/Nunito-Light.06e2db15.woff) format('woff'),
    url(/static/media/Nunito-Light.c41c2502.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Nunito-Regular.ca3385ad.woff2) format('woff2'), url(/static/media/Nunito-Regular.70805a4a.woff) format('woff'),
    url(/static/media/Nunito-Regular.65bb0a15.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  src: url(/static/media/Nunito-SemiBold.350f9905.woff2) format('woff2'), url(/static/media/Nunito-SemiBold.187972db.woff) format('woff'),
    url(/static/media/Nunito-SemiBold.713ac08d.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/Nunito-Bold.9345ba48.woff2) format('woff2'), url(/static/media/Nunito-Bold.3deafd53.woff) format('woff'),
    url(/static/media/Nunito-Bold.6f47bcfc.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  src: url(/static/media/Nunito-Black.e613c87f.woff2) format('woff2'), url(/static/media/Nunito-Black.862123c1.woff) format('woff'),
    url(/static/media/Nunito-Black.76e62b37.ttf) format('truetype');
}

body {
  background-color: #f8fbfe;
  color: #03073b;
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

